<template>
  <div>
    <form-components @submitForm="submitForm">
      <template v-slot:content>
        <el-form-item label="功能编号" >
            <el-col :span="8">
              <el-input v-model="menu.fid" ></el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="功能名称">
            <el-col :span="8">
              <el-input v-model="menu.name"></el-input>
            </el-col>
          </el-form-item>

           <el-form-item label="请求地址">
            <el-col :span="8">
              <el-input v-model="menu.url"></el-input>
            </el-col>
          </el-form-item>


           <el-form-item label="排序">
            <el-col :span="8">
              <el-input v-model="menu.sort"></el-input>
            </el-col>
          </el-form-item>

          <el-form-item label="父级">
            <el-col :span="8">
              <el-input v-model="menu.parentid"></el-input>
            </el-col>
          </el-form-item>

          <el-form-item label="功能描述">
            <el-col :span="8">
             <el-input
                type="textarea"
                :autosize="{ minRows:5, maxRows:10}"
                maxlength="100"
                v-model="menu.description"
                show-word-limit
                placeholder="请输入备注">
              </el-input>
            </el-col>
          </el-form-item>
      </template>
    </form-components>
  </div>
</template>

<script>
import FormComponents from "@/components/form/FormComponents";
import MenuApi from '@/api/MenuApi'
import { navTo,queryParam } from "@/utils/NavigatorUtils";
export default {
  name: "SaveUserPassPage",
  props: {
    msg: String,
  },
  components: {
    FormComponents
  },
  data() {
    return {
      options: [{
          value: '男',
          label: '男'
        }],
      menu:{
        fid:"",
        name:"",
        description:"",
        sort:"",
        funlevel:"",
        parentid:"男",
        url:"",
      }
    }
  },
  created(){
    this.menu = this.$route.params;
  },
  methods:{
    submitForm(){
      this.menu.id>0?this.updateMenuById():this.addMenu()
    },
    addMenu(){
      MenuApi.saveMenu(this.menu).then((resp)=>{
        if(resp.code == "200"){
          this.menu={}
          this.$successMsg(resp.msg)
         
        }else{
          this.$errorMsg(resp.msg)
        }
      })
    },
    updateMenuById(){
      MenuApi.updateMenu(this.menu).then((resp)=>{
        if(resp.code == "200"){
          this.user={}
          this.$successMsg(resp.msg);
           navTo(this,"/menulist")
        }else{
          this.$errorMsg(resp.msg)
        }
      })
    }
  }
};
</script>

