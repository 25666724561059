import * as AppHttpKit from '@/utils/http/AppHttpKit';
/**
 * 以下是完成API接口调用的定义规范
 */
const MenuApi = {
    search: (data) => AppHttpKit.postJSON(`/v1/menulist`,data),//这里必须写清楚接口的注释说明
    saveMenu: (data) => AppHttpKit.postJSON(`/v1/saveMenu`,data),
    queryMenuById: (data) => AppHttpKit.postJSON(`/v1/queryMenuById/`,data),
    updateMenu: (data) => AppHttpKit.postJSON(`/v1/updateMenu/`,data),
    deleteMenu: (data) => AppHttpKit.postJSON(`/v1/deleteMenu/`,data),
    batchDeleteMenu:(data) => AppHttpKit.postJSON(`/v1/batchDeleteMenu/`,data),
}
export default MenuApi;